<template>
  <!-- 高级搜索 -->
  <el-dialog
    :visible.sync="centerDialogVisible"
    width="60%"
    top="0.5%"
    center
    @close="closeDialog"
    :title="questionId?'编辑试题':'新增试题'"
    :close-on-click-modal="false"
  >
    <div style="height: 600px">
      <div class="ovy-a">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="questionId?'编辑试题':'添加试题'" name="first"></el-tab-pane>
        </el-tabs>
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <el-form-item label="题目类型" required class="el-form-questionType">
            <el-radio-group
              v-model="form.questionType"
              @change="formTypeChange"
            >
              <el-radio :label="10">单选题</el-radio>
              <el-radio :label="20">多选题</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选项组：" prop="optionGroupId">
            <el-select
                size="small"
                v-model="form.optionGroupId"
                clearable
                placeholder="请选择选项组"
                @change="changeOptionGroup"
            >
              <el-option
                  v-for="item in optionGroupList"
                  :key="item.optionGroupId"
                  :label="item.groupName"
                  :value="item.optionGroupId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题目内容" class="areaBox" required>
            <div
              ref="editorsquestionTitle"
              style="width: 100%; position: relative; z-index: 2"
            />
            <span v-show="showquestionTitle" class="showtitle"
              >请输入题目内容</span
            >
          </el-form-item>
          <el-form-item
            label
            v-if="form.questionType == 10"
            prop="questionAnswer"
          >
            <div>
              <el-radio-group v-model="form.questionAnswer">
                <div
                  v-for="(item, index) in form.optionList"
                  :key="item.optionId"
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                  "
                >

                  <el-form-item :prop="'optionList[' + index + '].optionName'">
                    <span style="display: inline-block;width: 48px;">选项{{ optionFM(index) }}&nbsp;&nbsp;</span>
                    <el-input
                      v-model="form.optionList[index].optionName"
                      style="width: 220px"
                      maxlength="255"
                      show-word-limit
                    ></el-input>
                    <el-upload
                      :on-change="
                        (res, file) => {
                          return handleAvatarSuccess2(res, file, index);
                        }
                      "
                      :before-upload="$beforeAvatarUpload"
                      :http-request="$requestMine"
                      :show-file-list="false"
                      class="img-el-upload"
                      action
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                    >

                      <el-image
                        :src="
                          form.optionList[index].optionImgUrl ||
                          require('@/assets/develop.png')
                        "
                        fit="contain"
                        class="imgCenter"
                      ></el-image>
                      <span style="line-height: 14px;" v-if="!form.optionList[index].optionImgUrl">上传图片</span>
                    </el-upload>
                    <span>&nbsp;分值：</span>
                    <el-input-number v-model="form.optionList[index].optionScore" :controls="false" :min="1" :max="999999" step="1" precision="0" style="width:80px"></el-input-number>
                    <el-button
                      v-if="form.optionList.length>2"
                      class="delet"
                      type="text"
                      @click="delOpenList(index)"
                      >删除选项</el-button
                    >
<!--                    <el-button-->
<!--                      v-if="form.questionAnswer == optionFM(index)"-->
<!--                      class="delet"-->
<!--                      type="text"-->
<!--                      >正确答案</el-button-->
<!--                    >-->
                  </el-form-item>
                </div>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item
            label
            v-if="form.questionType == 20"
            prop="questionAnswer2"
          >
            <div>
              <!-- <el-checkbox-group v-model="form.questionAnswer2"> -->
              <div
                v-for="(item, index) in form.optionListall"
                :key="index"
                style="display: flex; align-items: center; margin-bottom: 18px"
              >
                <el-form-item
                  :prop="'optionListall[' + index + '].optionName'"
                  class="checkbox"
                >
                  <span style="display: inline-block;width: 48px;">选项{{ optionFM(index) }}&nbsp;&nbsp;</span>
                  <el-input
                      v-model="form.optionListall[index].optionName"
                      style="width: 220px"></el-input>
                  <el-upload
                    :on-change="
                      (res, file) => {
                        return handleAvatarSuccess3(res, file, index);
                      }
                    "
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        form.optionListall[index].optionImgUrl ||
                        require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                    <span style="line-height: 14px;" v-if="!form.optionListall[index].optionImgUrl">上传图片</span>
                  </el-upload>
                  <span>&nbsp;分值：</span>
                  <el-input-number v-model="form.optionListall[index].optionScore" :controls="false" :min="1" step="1" precision="0" style="width:80px"></el-input-number>
                  <el-button
                    v-if="form.optionListall.length>2"
                    type="text"
                    class="delet"
                    @click="delOpenList(index)"
                    >删除选项</el-button
                  >
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label
            v-if="
              (form.questionType == 10 || form.questionType == 20) &&
              form.optionList.length < 26 &&
              form.optionListall.length < 26
            "
          >
            <el-button @click="addFromOpenList">添加选项</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button class="bgc-bv" @click="docancle()">取 消</el-button>
          <el-button class="bgc-bv" @click="addOptions">确 定</el-button>
        </span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import E from "wangeditor";

let editors = {};
export default {
  name: "questionPaperPopIBS",
  components: {
    // Empty,
  },
  mixins: [List],
  data() {
    return {
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      activeName: "first",
      showtitle: false,
      showquestionTitle: false,
      centerDialogVisible: false,
      QuestionList: [],
      form: {
        questionId:'',
        questionType: 10,
        optionGroupId: '',
        optionList: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
        ],
        optionListall: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          }
        ],
      },
      optionGroupList: [],
      ruleForm: {},
      rules: {
      },
      questionId: '',
    };
  },
  created() {
    this.getOptionGroupList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    // change选项组
    changeOptionGroup(val){
      if(val!=''){
        this.$post("/biz/hr/evaluate/option-group/getInfo", {optionGroupId: val }, 3000, true, 6)
            .then((ret) => {
              console.log(ret.data)
              if(this.form.questionType == 10){
                this.form.optionList = ret.data.options;
              }
              if(this.form.questionType == 20){
                this.form.optionListall = ret.data.options;
              }
            })
            .catch((err) => {
              return
            });
      }else{
          this.form.optionList = [
            {
              optionName: "",
              optionImgUrl: "",
              optionImg: "",
              optionScore: undefined,
            },
            {
              optionName: "",
              optionImgUrl: "",
              optionImg: "",
              optionScore: undefined,
            }
          ];
          this.form.optionListall = [
            {
              optionName: "",
              optionImgUrl: "",
              optionImg: "",
              optionScore: undefined,
            },
            {
              optionName: "",
              optionImgUrl: "",
              optionImg: "",
              optionScore: undefined,
            }
          ];
      }

    },
    // 选项组下垃集合
    getOptionGroupList() {
      this.$post("/biz/hr/evaluate/option-group/pageList", {
        groupName: '',
        pageNum: 1,
        pageSize: 0,
        enabled: true
      },3000,true,6)
        .then((res) => {
          if (res.status == 0) {
            this.optionGroupList = res.data.list || [];
          }
        })
        .catch(() => {
          return;
        });
    },


    showPopUp(paperId,questionId) {
      this.paperId = paperId;
      this.centerDialogVisible = true;
      this.showquestionTitle = false;
      if(questionId){
        this.questionId = questionId
        this.$nextTick(()=> {
          this.editorInit2(questionId);
        });
        // this.getsingle(questionId)
      }else{
        this.questionId = ''
        this.$nextTick(()=> {
          this.editorInit2();
        });
      }

    },
    // 获取单个数据
    getsingle(questionId) {
      this.$post("/biz/hr/evaluate/question/getInfo", { questionId }, 3000, true, 6)
          .then((ret) => {
            console.log(ret.data)
            const formData = ret.data;
            editors.txt.html(formData.questionName);
            this.form.questionType = Number(formData.questionType);
            this.form.questionId = formData.questionId;
            console.log(formData.options)
            formData.options.forEach((e,i)=>{
              if(!e.optionImgUrl){
                e.optionImgUrl = ''
              }
            })

            if (Number(formData.questionType) == 10) {
              this.form.optionList = formData.options;
            } else if (Number(formData.questionType) == 20) {
              this.form.optionListall = formData.options;
            }
          })
          .catch((err) => {
            return
          });
    },
    // 富文本2
    editorInit2(questionId) {
      editors = new E(this.$refs.editorsquestionTitle);

      editors.config.onchange = (html) => {
        this.ruleForm.questionTitle = html;
      };
      editors.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editors.config.uploadImgMaxSize = 1 * 1024 * 1024;
      // editors.config.pasteFilterStyle = "text";
      editors.config.pasteFilterStyle = false;
      editors.config.pasteIgnoreImg = true;
      editors.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };
      editors.config.pasteTextHandle = function (content) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
        // return content + '<p>在粘贴内容后面追加一行</p>'
        if (content == "" && !content) return "";
        let input = content;
        // 1. remove line breaks / Mso classes
        var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
        var output = input.replace(stringStripper, " ");
        // 2. strip Word generated HTML comments
        var commentSripper = new RegExp("<!--(.*?)-->", "g");
        var output = output.replace(commentSripper, "");
        var tagStripper = new RegExp(
          "<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>",
          "gi"
        );
        // 3. remove tags leave content if any
        output = output.replace(tagStripper, "");
        // 4. Remove everything in between and including tags '<style(.)style(.)>'
        var badTags = [
          "style",
          "script",
          "applet",
          "embed",
          "noframes",
          "noscript",
        ];

        for (var i = 0; i < badTags.length; i++) {
          tagStripper = new RegExp(
            "<" + badTags[i] + ".*?" + badTags[i] + "(.*?)>",
            "gi"
          );
          output = output.replace(tagStripper, "");
        }
        // 5. remove attributes ' style="..."'
        var badAttributes = ["style", "start"];
        for (var i = 0; i < badAttributes.length; i++) {
          var attributeStripper = new RegExp(
            " " + badAttributes[i] + '="(.*?)"',
            "gi"
          );
          output = output.replace(attributeStripper, "");
        }
        console.log("output----", output);
        return output;
      };
      editors.create();
      if(questionId){
        this.getsingle(questionId)
      }
    },
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      } else if (msg == 6) {
        return "G";
      } else if (msg == 7) {
        return "H";
      } else if (msg == 8) {
        return "I";
      } else if (msg == 9) {
        return "J";
      } else if (msg == 10) {
        return "K";
      } else if (msg == 11) {
        return "L";
      } else if (msg == 12) {
        return "M";
      } else if (msg == 13) {
        return "N";
      } else if (msg == 14) {
        return "O";
      } else if (msg == 15) {
        return "P";
      } else if (msg == 16) {
        return "Q";
      } else if (msg == 17) {
        return "R";
      } else if (msg == 18) {
        return "S";
      } else if (msg == 19) {
        return "T";
      } else if (msg == 20) {
        return "U";
      } else if (msg == 21) {
        return "V";
      } else if (msg == 22) {
        return "W";
      } else if (msg == 23) {
        return "X";
      } else if (msg == 24) {
        return "Y";
      } else if (msg == 25) {
        return "Z";
      }
    },
    addOptions() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (editors.txt.html() == "") {
            this.showquestionTitle = true;
            return false;
          } else {
            this.showquestionTitle = false;
            if(editors.txt.html().length>2000){
              this.$message({
                type: "error",
                message: "题目内容不允许超过2000字符！"
              });
              return
            }else{

              const that = this;
              let parmar = {
                paperId: that.paperId,
                // questionOrigin: that.form.questionOrigin,
                questionName: editors.txt.html(),
                questionType: that.form.questionType,
                rowOptionNum: '1',
                options: []
              };
              let qbQuestionOptions = [];
              let hasNull = false
              if (that.form.questionType == 10) {
                for (var i = 0; i < that.form.optionList.length; i++) {
                  if(!that.form.optionList[i].optionName&&!that.form.optionList[i].optionImgUrl&&!hasNull){
                    this.$message.error("选项内容和选项图片至少填写一个,请补全第"+(i+1)+"个选项");
                    hasNull = true
                    return false
                  }
                  if(!that.form.optionList[i].optionScore&&!hasNull){
                    this.$message.error("请填写第"+(i+1)+"个选项分数");
                    hasNull = true
                    return false
                  }
                  qbQuestionOptions.push({
                    optionName: that.form.optionList[i].optionName,
                    optionImgUrl: that.form.optionList[i].optionImgUrl,
                    optionImg: that.form.optionList[i].optionImg,
                    optionScore: that.form.optionList[i].optionScore,
                    optionOrder: i
                  });
                }
                parmar.options = qbQuestionOptions;
              } else if (that.form.questionType == 20) {
                for (var i = 0; i < that.form.optionListall.length; i++) {
                  if(!that.form.optionListall[i].optionName&&!that.form.optionListall[i].optionImgUrl&&!hasNull){
                    this.$message.error("选项内容和选项图片至少填写一个,请补全第"+(i+1)+"个选项");
                    hasNull = true
                    return false
                  }
                  if(!that.form.optionListall[i].optionScore&&!hasNull){
                    this.$message.error("请填写第"+(i+1)+"个选项分数");
                    hasNull = true
                    return false
                  }
                  qbQuestionOptions.push({
                    optionName: that.form.optionListall[i].optionName,
                    optionImgUrl: that.form.optionListall[i].optionImgUrl,
                    optionImg: that.form.optionListall[i].optionImg,
                    optionScore: that.form.optionListall[i].optionScore,
                    optionOrder: i
                  });
                }
                parmar.options = qbQuestionOptions;
              }
              console.log(parmar)
              let url = '/biz/hr/evaluate/question/insert'
              if(this.questionId){
                parmar.questionId = this.questionId
                url = '/biz/hr/evaluate/question/modify'
              }
              if(!hasNull){
                that
                    .$post(url, parmar, 3000, true, 6)
                    .then((ret) => {
                      if (ret.status == "0") {
                        this.$message({
                          message: ret.message,
                          type: "success",
                        });
                      }
                      that.centerDialogVisible = false;
                      that.empty();
                      that.$parent.getData()
                      that.$emit("getnewdatap");
                      that.$emit("subShows", true);
                      if (ret.status == "-1") {
                        this.$message.error(ret.message);
                      }
                    })
                    .catch((err) => {
                      return;
                    });
              }
            }


          }
        }
      });
    },
    delOpenList(index) {
      if (this.form.questionType == 10) {
        this.form.optionList.splice(index, 1);
      } else if (this.form.questionType == 20) {
        this.form.optionListall.splice(index, 1);
      }
    },
    addFromOpenList() {
      let data = {
        optionName: "",
        optionImgUrl: "",
        optionImg: "",
        optionScore: undefined,
      };
      if (this.form.questionType == 10) {
        this.form.optionList.push(data);
      } else if (this.form.questionType == 20) {
        this.form.optionListall.push(data);
      }

      this.$forceUpdate();
    },
    docancle() {
      this.centerDialogVisible = false;
      this.questionId = ''
      editors.destroy()
      this.empty();
    },
    empty() {
      this.form = {
        questionType: 10,
        optionGroupId: '',
        optionList: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
        ],
        optionListall: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
        ],
      };
      this.$refs["form"].resetFields();
      editors.txt.clear();
    },
    closeDialog() {
      this.centerDialogVisible = false;
      editors.destroy();
      this.empty();
    },
    formTypeChange(value) {
      this.$refs["form"].resetFields();
      editors.txt.clear();
      this.form = {
        questionType: value,
        optionGroupId: '',
        optionList: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
        ],
        optionListall: [
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
          {
            optionName: "",
            optionImgUrl: "",
            optionImg: "",
            optionScore: undefined,
          },
        ],
      };
    },
    //图片上传
    handleAvatarSuccess2(res, fiie, index) {

      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "CT_QUESTION_BANK");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/file/upload", formData, 3000, true, 6)
        .then((result) => {
          that.form.optionList[index].optionImg = result.data.fileKey || "";
          that.form.optionList[index].optionImgUrl = result.data.fileUrl || "";
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    //图片上传
    handleAvatarSuccess3(res, fiie, index) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.form.optionListall[index].optionImg = result.data.fileKey || "";
          that.form.optionListall[index].optionImgUrl = result.data.fileURL || "";
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
  },
};
</script>
<style lang="less">
.el-input-number {
  .el-input__inner {
    line-height: 40px;
    height: 40px;
  }
}
.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
.el-form-questionType/deep/.el-form-item__content {
  margin-left: 0 !important;
}
.w-e-text-container {
  height: 250px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
}
</style>
<style lang="less" scoped>
.el-form-questionType {
  display: flex;
}
.el-form-item__content {
  display: flex;
  align-items: center;
  .el-radio-group .el-form-item /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
  }
  .checkbox /deep/ .el-form-item__content {
    display: flex;
    align-items: center;
    .el-checkbox {
      display: flex;
      align-items: center;
      .el-checkbox__label {
        display: flex;
        align-items: center;
        .el-input {
          margin-left: 1.5rem;
        }
      }
    }
  }
}
.delet {
  margin-left: 0.5rem;
}
.el-form-item__content .img-el-upload /deep/.el-upload {
  margin-left: 15px;
  width: 60px;
  height: 60px !important;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
</style>
