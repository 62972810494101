<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goHerf">测评列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
          >题目设置</a
          >
          <p v-html="$xss(test)"></p>
        </span>
      </div>
      <div class="framePage-halfBody">
        <div class="framePage-article">
          <div class="ovy-a">
            <div>
              <div>
                <div class="title">
                  <h3>基本信息</h3>
                </div>
                <div class="titleMation">
                  <span style="color: #4089fa">{{ $route.query.row.paperName }}</span>
                </div>
              </div>
              <div>
                <div class="title">
                  <h3>题库类型</h3>
                </div>
                <div class="searchBox">
                  <el-input
                      v-model="questionTitle"
                      type="text"
                      size="small"
                      placeholder="请输入题目内容"
                      clearable
                  >
                    <el-button slot="append" class="bgc-bv" @click="getData()"
                    >搜索</el-button
                    ></el-input
                  >
                </div>
                <div class="searchqusetionBox">
                  <div
                      v-for="(item, index) in items"
                      :key="index"
                      @click="clickTap(index, item.id)"
                  >
                    <span :class="currentClass(index)">
                      <img :src="item.img" />
                      {{ item.name }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="btnBox" v-if="published == false">
                <el-button type="primary" class="bgc-bv" @click="addquestion"
                >新增试题</el-button
                >
                <el-button type="primary" class="bgc-bv" @click="bulkIimport"
                >批量添加</el-button
                >

              </div>
            </div>
          </div>
        </div>
        <div class="framePage-body">
          <div class="ovy-a">
            <div class="searchbox">
              <div class="flexcb searchbox" style="justify-content: center">
                <h2 style="text-align: center">{{ $route.query.row.paperName }}</h2>
              </div>
            </div>
            <div
                class="framePage-scroll flexdc"
                :style="!tableData.length ? 'height:93%' : ''"
            >
              <div class="ovy-a flex1" id="list-box">
                <div
                    class="list hoverShow"
                    v-for="(item, index) in tableData"
                    :key="index"
                    v-show="tableData.length > 0"
                >
                  <div class="list-item df">
                    <span
                        class="tixing"
                        :class="{
                        tixing1: item.questionType == 10,
                        tixing2: item.questionType == 20
                      }"
                    >{{
                        $setDictionary(
                            "HR_QUESTION_TYPE",
                            item.questionType
                        )
                      }}</span
                    >
                    <div class="subject flex1">
                      <div class="subject-title">
                        <span
                            v-html="$xss(item.questionName)"
                            style="display: flex;max-width: 100%"
                        >{{ item.questionName }}</span
                        >
                      </div>
                      <div
                          class="option"
                          v-if="item.questionType == 10"
                      >
                        <span
                            v-for="(items, indexs) in item.options"
                            :key="indexs"
                        >{{ letterArr[indexs] }}.{{ items.optionName }}
                          <el-image
                              v-if="items.optionImgUrl"
                              class="qbimg"
                              :src="
                              items.optionImgUrl ||
                              require('@/assets/develop.png')
                            "
                              fit="contain"
                          ></el-image>
                        </span>
                      </div>
                      <div
                          class="option"
                          v-if="item.questionType == 20"
                      >
                        <span
                            v-for="(items, indexs) in item.options"
                            :key="indexs"
                        >{{ letterArr[indexs] }}.{{ items.optionName }}
                          <el-image
                              v-if="items.optionImgUrl"
                              class="qbimg"
                              :src="
                              items.optionImgUrl ||
                              require('@/assets/develop.png')
                            "
                              fit="contain"
                          ></el-image
                          ></span>
                      </div>
                      <div class="df showbtn" style="float: right"  v-if="published == false">
                        <div style="white-space: nowrap">
                          <el-button
                              style="padding: 8px 20px"
                              v-show="
                              index !== 0 &&
                              TopictypeVal == '' &&
                              questionTitle == ''
                            "
                              @click="onSort(item.questionId, 'top')"
                          >上移</el-button
                          >
                          <el-button
                              style="padding: 8px 20px"
                              v-show="
                              index !== tableData.length - 1 &&
                              TopictypeVal == '' &&
                              questionTitle == ''
                            "
                              @click="
                              onSort(item.questionId, 'bottom')
                            "
                          >下移</el-button
                          >
                          <el-button
                              style="padding: 8px 20px"
                              @click="onEdit(item.questionId)"
                          >编辑</el-button
                          >
                          <el-button
                              style="padding: 8px 20px"
                              @click="onDel(item.questionId)"
                          >删除</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Empty2 slot="empty" v-show="!tableData.length" />
            </div>
          </div>
          <PageNum
              :apiData="apiData"
              @sizeChange="sizeChange"
              @getData="getData"
          />
        </div>
      </div>
    </div>
    <!-- end -->
    <!-- 新增编辑试题弹层start -->
    <questionPaperPopIBS
        ref="questionPaperPopIBS"
        @getnewdatap="getData"
        @subShows="subShows"
    />
    <!-- end -->
    <!-- 批量上传start -->
<!--    <questionPaperIBS ref="questionPaperIBS" @eventBus="paperBack" />-->
    <!-- end -->


    <!--快速导入-->
    <el-dialog
        title="快速导入"
        :visible.sync="centerDialogVisible"
        width="60%"
        top="2%"
        center
        :before-close="doCloseload"
        class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>选择选项组</span>
          </h1>
          <div class="df">
            <el-form ref="form" :model="form" label-width="120px" :rules="rules" style="margin-top: 16px;">
              <el-form-item label="选项组：" prop="optionGroupId">
                <el-select
                    size="small"
                    v-model="form.optionGroupId"
                    clearable
                    placeholder="请至少输入两个字搜索"
                    @change="changeOptionGroup"
                >
                  <el-option
                      v-for="item in optionGroupList"
                      :key="item.optionGroupId"
                      :label="item.groupName"
                      :value="item.optionGroupId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                  label="选项信息："
                  prop="questionAnswer"
                  v-if="form.optionList.length>0"
              >
                <div style="position:relative;">
                  <el-radio-group v-model="form.questionAnswer">
                    <div
                        v-for="(item, index) in form.optionList"
                        :key="index"
                        style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                  "
                    >
                      <el-form-item :prop="'optionList[' + index + '].optionName'">
                        选项{{ optionFM(index) }}&nbsp;&nbsp;
                        <el-input
                            v-model="form.optionList[index].optionName"
                            disabled
                            style="width: 220px"
                        ></el-input>
                        <el-upload
                            :on-change="
                        (res, file) => {
                          return handleAvatarSuccess2(res, file, index);
                        }
                      "
                            :before-upload="$beforeAvatarUpload"
                            :http-request="$requestMine"
                            :show-file-list="false"
                            class="img-el-upload"
                            action
                            accept="image/png, image/gif, image/jpg, image/jpeg"
                            disabled
                        >
                          <el-image
                              :src="
                          form.optionList[index].optionImgUrl ||
                          require('@/assets/develop.png')
                        "
                              fit="contain"
                              class="imgCenter"
                          ></el-image>
                          <span style="line-height: 14px;" v-if="!form.optionList[index].optionImgUrl">上传图片</span>
                        </el-upload>
                        <span>&nbsp;分值：</span>
                        <el-input-number v-model="form.optionList[index].optionScore" :controls="false" :min="1" step="1" precision="0" style="width:80px" disabled></el-input-number>

                      </el-form-item>
                    </div>
                  </el-radio-group>
                </div>
              </el-form-item>


            </el-form>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span
                @click="downloadExcelgs"
                style="margin-top: 20px;color: #409EFF;cursor: pointer"
            >
              测评题目导入.xlsx
            </span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload
                  class="upload-demo upload-workers"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-change="uploadChange"
                  :show-file-list="false"
                  :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size: 12px;"
                >浏览</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button
                  class="bgc-bv"
                  size="mini"
                  style="margin-top: 10px; height: 35px"
                  :disabled="doExportInDisabled"
                  @click="doExport"
              >开始导入</el-button
              >
            </div>
            <div style="margin-top: 10px" v-if="progressVal > 0">
              <el-progress
                  :text-inside="true"
                  :stroke-width="20"
                  :percentage="progressVal"
              ></el-progress>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            4
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>题目总数量:{{ totalNum }}题;</span>
            <span>成功:{{ correctNum }}题;</span>
            <span>失败:{{ errorNum }}题;</span>
          </div>
          <div>
            <el-button
                class="bgc-bv"
                style="margin-top: 15px; height: 35px"
                size="mini"
                :disabled="errorNum == '0'"
                @click="doExportError"
            >导出错误数据</el-button
            >
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败题目，请导出错误数据，将导入失败题目调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty2 from "@/components/Empty2.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import questionPaperPopIBS from "@/views/miniApp/evaluation/questionPaperPop.vue";
// import questionPaperIBS from "@/views/miniApp/evaluation/questionbankUpload.vue";

export default {
  name: "itemBankSetting",
  components: {
    Empty2,
    PageNum,
    questionPaperPopIBS,
    // questionPaperIBS
  },
  mixins: [List],
  data() {
    return {
      test: `<a onclick='alert("xss攻击")'>链接</a>`,
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      currentNumber: -1,
      items: [
        {
          img: require("@/assets/radio.png"),
          name: "单选题",
          id: "10",
        },
        {
          img: require("@/assets/check.png"),
          name: "多选题",
          id: "20",
        },
      ],

      TopictypeVal: "",
      questionTitle: "",


      paperId:'',
      published: false,


      // 快速导入
      centerDialogVisible: false,
      // newvalues: [], // 存储value的数组
      // Course: [], //
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      batchId: "",
      importType: "",
      fileName: "",
      fileKey: "",
      excelUrl: "",

      form: {
        optionGroupId:'',
        optionList: []
      },
      rules: {
        optionGroupId: [{ required: true, message: "请选择选项组", trigger: "change" }],
      },
      optionGroupList: [],//选项组下垃集合
    };
  },
  computed:{},
  created () {
    this.getOptionGroupList();
  },
  mounted() {
      if(this.$route.query.row&&this.$route.query.row.paperId){
        this.paperId = this.$route.query.row.paperId
        localStorage.setItem('CPpaperId', this.$route.query.row.paperId);
      }else if(localStorage.getItem('CPpaperId')){
        this.paperId = localStorage.getItem('CPpaperId')
      }

      if(this.$route.query.row&&(this.$route.query.row.published == true||this.$route.query.row.published == false)){
        this.published = this.$route.query.row.published
        localStorage.setItem('CPpublished', this.$route.query.row.published);
      }else if(localStorage.getItem('CPpublished')){
        this.published = localStorage.getItem('CPpublished') == 'false'?false:localStorage.getItem('CPpublished') == 'true'?true:false
      }
  },
  filters: {
    filterFromItem(type){
      let res
      switch (type) {
        case '10':
          res = '单选'
          break;
        case '20':
          res = '多选'
          break;
        default:
          res = '未知'
          break;
      }
      return res
    },
  },
  methods: {
    // change选项组
    changeOptionGroup(val){
      console.log(val)
      if(val!=''){
        this.$post("/biz/hr/evaluate/option-group/getInfo", {optionGroupId: val }, 3000, true, 6)
            .then((ret) => {
              console.log(ret.data)
              this.form.optionList = ret.data.options;
            })
            .catch((err) => {
              return
            });
      }else{
        this.form.optionList = []
      }

    },
    // 选项组下垃集合
    getOptionGroupList() {
      this.$post("/biz/hr/evaluate/option-group/pageList", {
        groupName: '',
        pageNum: 1,
        pageSize: 0,
        enabled: true
      },3000,true,6)
        .then((res) => {
          if (res.status == 0) {
            this.optionGroupList = res.data.list || [];
          }
        })
        .catch(() => {
          return;
        });
    },

    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      } else if (msg == 6) {
        return "G";
      } else if (msg == 7) {
        return "H";
      } else if (msg == 8) {
        return "I";
      } else if (msg == 9) {
        return "J";
      } else if (msg == 10) {
        return "K";
      } else if (msg == 11) {
        return "L";
      } else if (msg == 12) {
        return "M";
      } else if (msg == 13) {
        return "N";
      } else if (msg == 14) {
        return "O";
      } else if (msg == 15) {
        return "P";
      } else if (msg == 16) {
        return "Q";
      } else if (msg == 17) {
        return "R";
      } else if (msg == 18) {
        return "S";
      } else if (msg == 19) {
        return "T";
      } else if (msg == 20) {
        return "U";
      } else if (msg == 21) {
        return "V";
      } else if (msg == 22) {
        return "W";
      } else if (msg == 23) {
        return "X";
      } else if (msg == 24) {
        return "Y";
      } else if (msg == 25) {
        return "Z";
      }
    },
    subShows(params) {
      this.subShow = params;
    },
    /* 获取数据start */
    getData(pageNum = 1) {
      // console.log(this.audit);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperId: this.$route.query.row.paperId || localStorage.getItem('CPpaperId') || this.paperId,
      };
      if (this.TopictypeVal) {
        params.questionType = this.TopictypeVal;
      }
      if (this.questionTitle) {
        params.questionName = this.questionTitle;
      }
      console.log("params", params);
      this.doFetch(
          {
            url: "/biz/hr/evaluate/question/pageList",
            params,
            pageNum,
          },
          true,
          6
      );
    },
    /* 题库类型切换数据 start*/
    clickTap(index, id) {
      this.currentNumber = index;
      this.TopictypeVal = id;
      this.questionTitle = "";
      this.getData();
    },
    currentClass(index) {
      return [this.currentNumber == index ? "addclass" : ""];
    },
    /* end */
    /* 新增试题start 编辑试题 */
    onEdit(questionId) {
      this.$refs.questionPaperPopIBS.showPopUp(this.paperId,questionId);
    },
    addquestion() {
      this.$refs.questionPaperPopIBS.showPopUp(this.paperId);
    },

    /* end */
    /* 上移&&下移start */
    onSort(questionId, stu) {
      this.$post(
          stu == "top"
              ? "/biz/hr/evaluate/question/orderUp"
              : "/biz/hr/evaluate/question/orderDown",
          { questionId },
          3000,
          true,
          6
      )
          .then((ret) => {
            if (ret.status == 0) {
              this.getData(-1);
            }
          })
          .catch((err) => {
            return;
          });
    },
    /* end */
    /* 试题删除start */
    onDel(questionId) {
      const that = this;
      that
          .$confirm(
              "你确定要从" + that.$route.query.row.paperName + "中删除该试题吗",
              "删除",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                confirmButtonClass: "confirmButtonClass",
                type: "warning",
              }
          )
          .then(() => {
            that
                .$post("/biz/hr/evaluate/question/delete", { questionId }, 3000, true, 6)
                .then((ret) => {
                  if (ret.status == 0) {
                    that.subShow = true;
                    that.getData(-1);
                  } else {
                    that.$message({
                      type: "error",
                      message: ret.message,
                    });
                  }
                });
          })
          .catch(() => {
            return;
          });
    },
    // /* 批量上传start */
    // BatchUpload() {
    //   this.$refs.questionPaperIBS.showPopUp(this.paperId);
    // },
    paperBack() {
      this.getData();
    },
    /* 返回 */
    goHerf() {
      let urlPath = ''
      if(this.$route?.query?.row?.ctype == 'yw'){
        urlPath = '/web/miniApp/evaluation/evaluationList'
      }
      if(this.$route?.query?.row?.ctype == 'jg'){
        urlPath = '/web/evaluationManagement/jgevaluationList'
      }
      this.$router.push({
        path: urlPath,
        query: {
          refresh: true,
        },
      });
    },
    // 导入
    bulkIimport() {
      this.totalNum = "0";
      this.errorNum = "0";
      this.correctNum = "0";
      this.batchId = "";
      this.centerDialogVisible = true;
    },
    // 关闭快速导入
    doCloseload() {
      this.centerDialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0;
      this.doExportInDisabled = false;
      this.form = {
        optionGroupId:'',
        optionList: []
      }
      this.optionGroupList = []
      this.$refs["form"].resetFields();
      this.getData();
    },
    // 下载模板
    downloadExcelgs(templatePath) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = '/static/测评题目导入.xlsx';
      let templateNames = '测评题目导入.xlsx';
      link.setAttribute("download", templateNames);
      document.body.appendChild(link);
      link.click();
    },
    // 导入人才
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.fileKey = result.data.fileKey;
          })
          .catch((err) => {
            return;
          });
    },
    // 导入
    doExport() {
      if(this.form.optionGroupId == ""){
        this.$message({
          message: "请选择选项组",
          type: "warning",
        });
      }else if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.doExportInDisabled = true;
        if (this.fileKey) {
          const parmar = {
            excelUrl: this.fileKey,
            optionGroupId: this.form.optionGroupId,
            paperId: this.paperId
          };
          this.$post("/biz/hr/evaluate/question/importQuestion", parmar, 5000,true,6)
              .then((res) => {
                if (res.status == 0) {
                  if (res.status == 0) {
                    console.log(res.message);
                    this.doProgress(res.message);
                  }
                }
              })
              .catch(() => {
                return;
              });
        } else {
          this.$message({
            message: "请选择文件",
            type: "warning",
          });
        }
      }
    },
    // 导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
            "/biz/hr/evaluate/question/exportError",
            { batchId: this.batchId },
            5000,true,6
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    // 获取导入进度
    doProgress(batchId) {
      this.$post(
          "/biz/hr/evaluate/question/importProgress",
          { batchId },
          5000,true,6
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="less">
.el-upload-list__item {
  /deep/img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    z-index: 1;
  }
}
.h3 {
  padding: 0.75rem 0;
}
</style>
<style lang="less" scoped>
.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-right: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 20%;
  min-width: 20rem;
  .title {
    display: flex;
    justify-content: space-between;
    position: relative;
    h3 {
      font-size: 0.85rem;
    }
    h3:before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -14px;
    }
    .title-button {
      padding: 0;
      font-size: 0.8rem;
    }
  }
  .searchBox {
    display: flex;
    margin: 15px 0;
  }
  .searchqusetionBox {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 50%;
      display: flex;
      margin: 15px 0;
      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
      .addclass {
        color: #4574f9;
      }
    }
  }
  .titleMation {
    display: flex;
    flex-direction: column;
    padding: 0.9rem 0 1.8rem 0;
    span:nth-child(2) {
      padding: 0.5rem 0;
    }
  }
  .btnBox {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        justify-content: space-between;
        .number {
          padding-left: 1rem;
        }
        img {
          width: 100px;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
          display: flex;
          align-items: center;
          .qbimg {
            width: 5rem;
          }
        }
      }
    }
  }
}
.img-boxs {
  img {
    width: 100px;
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.informationmBox {
  display: flex;
  justify-content: space-between;
}
.el-divider--horizontal /deep/ {
  margin: 12px 0;
}
.el-cascader /deep/ {
  width: 100%;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card /deep/.el-upload-list__item-actions {
  z-index: 9999;
}
.showbtn {
  display: none;
}
.hoverShow :hover {
  .showbtn {
    display: block;
  }
}
/deep/ .el-input--mini .el-input__inner{
  height: 20px!important;
  line-height: 20px!important;
}
/deep/.el-form-item__content{
  display: flex;
  align-items: center;
}
/deep/.el-form-item__content .img-el-upload{
  display: inline-block;
}
/deep/.el-form-item__content .img-el-upload .el-upload{
  margin-left: 15px;
  width: 60px;
  height: 60px !important;
}
.exportdialog {
  .upload-workers {
    width: 80px;
    height: 35px;
    .el-upload {
      width: 100%!important;
      height: 35px!important;
      border: none !important;
      .el-button {
        width: 100%!important;
        height: 32px!important;
        padding: 5px 0;
      }
    }
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
/deep/.list .list-item .subject .option {

  flex-wrap: wrap;
  justify-content: start;
}
/deep/.list .list-item .subject .option span{
  width: 25%;
  word-wrap: break-word;
  word-break: break-all;
  align-items: flex-start;
  line-height: 1.5;
}
</style>
